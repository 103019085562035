<div class="container footer text-muted">
  <div class="row">
    <div class="col">&copy; {{ year }} Vaultwarden.</div>
    <div class="col text-center"></div>
    <div class="col text-right">
      {{ "versionNumber" | i18n: version }}
      <br/>
      Mon Apr 10 08:42:21 UTC 2023
      <br/>
      c3f3481528197601109a3aaeb32b7ab9c1f4a5f4
    </div>
  </div>
</div>
